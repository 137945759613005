import React, { useEffect, useState } from 'react'
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Login } from './Pages/Login';
import Home from './Pages/Home';
import axios from 'axios';
import './Styles/index.scss';
import { refreshSession } from './aws/Authenticate';
import { CognitoUser } from 'amazon-cognito-identity-js';
import Userpool from './aws/Userpool';

const apiUser = "admin";
const apiPwd = "brick-grape-megaton";
export const authCode = btoa(apiUser + ":" + apiPwd);
// console.log('authentication2 : ' + authCode);
export const headers = {
  'Accept': 'application/json',
  'Content-Type': 'application/json',
  'Authorization': 'Basic ' + authCode
};

const App = () => {
  const [username, setUsername] = useState('');
  const [tokenData, setTokenData] = useState(null);

  let refreshTimeout = null;
  const setRefreshTimeout = (time) => {
    refreshTimeout = setTimeout(() => {
      clearTimeout(refreshTimeout);
      refreshSession(tokenData)
        .then(session => {
          reCheck({ username: session.idToken.payload.email, tokenData: session });
          setRefreshTimeout((session.idToken.payload.exp - 60000));
        })
        .catch(err => console.warn);
    }, time)
  }

  const reCheck = (userData) => {
    setUsername(userData.username);
    setTokenData(userData.tokenData);
    if (!userData.tokenData && refreshTimeout) {
      clearTimeout(refreshTimeout);
      localStorage.removeItem('localusersession');
    }
    else localStorage.setItem('localusersession', JSON.stringify(userData.tokenData));
  }


  const cognitoUserSessionStr = localStorage.getItem('localusersession');
  if (cognitoUserSessionStr && cognitoUserSessionStr !== 'null') {
    const cognitoUserSession = JSON.parse(cognitoUserSessionStr);
    if (Date.now() >= cognitoUserSession.idToken.payload.exp * 1000)
      refreshSession(cognitoUserSession)
        .then(session => {
          reCheck({ username: session.idToken.payload.email, tokenData: session });
          setRefreshTimeout((session.idToken.payload.exp - 60000));
        })
        .catch(err => console.warn);
    else if (!tokenData) {
      reCheck({
        username: cognitoUserSession.idToken.payload.email,
        tokenData: cognitoUserSession
      });
    }
  }

  /**
  * @description This function used to add JWT token every api request
  */
  axios.interceptors.request.use(function (config) {
    // Do something before request is sent
    // console.log(config);
    if (tokenData) {
      try {
        config.headers.token = tokenData.idToken.jwtToken;
      } catch (err) { }
      // config.headers['Connection'] = 'keep-alive';
      try {
        config.headers['Content-Type'] = headers['Content-Type'];
      } catch (err) { }
      try {
        config.headers['Accept'] = '*/*';
      } catch (err) { }
      try {
        config.url = config.url.replaceAll('OUR_BACKEND', 'https://api.sausalitolab.com');
      } catch (err) { }
      // config.headers['Accept-Encoding'] = 'gzip, deflate, br';
      // config.url = config.url.replace('OUR_BACKEND', 'http://localhost:3030');
      // console.log(config);
    }
    return config;
  }, function (error) {
    // Do something with request error
    return Promise.reject(error);
  });

  axios.interceptors.response.use(function (response) {
    return response;
  }, function (error) {
    return Promise.resolve(error);
  });

  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={(tokenData) ? (<Home userEmail={username} jwtToken={tokenData.accessToken.jwtToken} reCheck={reCheck} />) : (<Login reCheck={reCheck} />)}>
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default App
