import { AuthenticationDetails, CognitoRefreshToken, CognitoUser, CognitoUserAttribute } from "amazon-cognito-identity-js";
import Userpool from "./Userpool";

export const authenticate = (Email, Password) => {
    console.log(Email)
    console.log(Password)
    return new Promise((resolve, reject) => {
        const user = new CognitoUser({
            Username: Email,
            Pool: Userpool
        });

        const authDetails = new AuthenticationDetails({
            Username: Email,
            Password: Password
        });

        user.authenticateUser(authDetails, {
            onSuccess: (result) => {
                // alert('Login success');
                resolve({ msg: 'SUCCESS', data: result });
            },
            onFailure: (err) => {
                // alert('Login failed : ' + err);
                reject(err);
            },
            newPasswordRequired: (data) => {
                resolve({ msg: 'NEW-PASSWORD-REQUIRED', data: data, user: user });
            },
        })
    })
}


export const logout = () => {
    try {
        Userpool.getCurrentUser().signOut();
        return true;
    } catch (err) {
        console.error(err);
        return false;
    }
    // Userpool.getCurrentUser().signOut();
    // window.location.href = '/';
}


export const signup = (attrList, email, password) => {
    attrList.push(
        new CognitoUserAttribute({
            Name: 'email',
            value: email,
        })
    );
    let username = email;
    Userpool.signUp(username, password, attrList, null, (err, data) => {
        if (err) {
            alert('Signup error');
            console.error(err);
        } else {
            alert('Signup success');
            console.log(data);
        }
    })
}

export const changePassword = (cognitoUser, userAttr, newPassword) => {
    return new Promise((resolve, reject) => {
        cognitoUser.completeNewPasswordChallenge(newPassword, [], {
            onSuccess: result => {
                resolve(result);
            },
            onFailure: result => {
                console.error(result);
                reject();
            }
        });
    })
};
export const refreshSession = (tokenData) => {
    return new Promise((resolve, reject) => {
        // if (cognitoUser) console.log(cognitoUser);

        const user = new CognitoUser({
            Username: tokenData.idToken.payload.email,
            Pool: Userpool
        });
        user.signInUserSession = tokenData;
        // user.userDataKey = cognitoUser.userDataKey;
        const refreshToken = new CognitoRefreshToken({ RefreshToken: tokenData.refreshToken.token });
        user.refreshSession(refreshToken, (err, session) => {
            if (err) reject(err);
            else { localStorage.setItem('localusersession', JSON.stringify(session)); resolve(session) };
        })
    })
}