import React, { useState, useEffect } from 'react'
import '../Styles/Login.css'
import { authenticate, changePassword } from '../aws/Authenticate'
import { useNavigate } from 'react-router-dom'

export const Login = ({ reCheck }) => {

    const navigate = useNavigate();
    useEffect(() => { navigate('/') }, [])
    const [enableSubmitBtn, setEnableSubmitBtn] = useState(true);

    const [isAuthErr, setIsAuthErr] = useState(false);
    const [confPassErr, setConfPassError] = useState(false);

    const [newPassRequired, setNewPasswordRequired] = useState(null);

    const [passwordFiledType, setPasswordFieldType] = useState('password');
    const handlePasswordShowHide = () => { setPasswordFieldType((passwordFiledType === 'password') ? 'text' : 'password'); }

    const [newPasswordFiledType, setNewPasswordFieldType] = useState('password');
    const handleNewPasswordShowHide = () => { setNewPasswordFieldType((newPasswordFiledType === 'password') ? 'text' : 'password'); }

    const [newConfPasswordFiledType, setNewConfPasswordFieldType] = useState('password');
    const handleNewConfPasswordShowHide = () => { setNewConfPasswordFieldType((newConfPasswordFiledType === 'password') ? 'text' : 'password'); }

    const handleFormSubmit = (event) => {
        event.preventDefault();
        if (enableSubmitBtn) {
            setEnableSubmitBtn(false);
            if (newPassRequired) { handlePasswordChange(event); return; }
            const username = event.target.elements.username.value;
            const password = event.target.elements.password.value;
            // console.log(username);
            // console.log(password);
            authenticate(username, password).then((data) => {
                console.log(data);
                if (data.msg === 'SUCCESS') {
                    reCheck({
                        username: username,
                        tokenData: data.data
                    });
                } else if (data.msg === 'NEW-PASSWORD-REQUIRED') {
                    setNewPasswordRequired({
                        data: data.data,
                        user: data.user
                    });
                }
                setEnableSubmitBtn(true);
            }, (err) => { console.error(err); setIsAuthErr(true); setEnableSubmitBtn(true); }
            ).catch((err) => { console.error(err); setIsAuthErr(true); setEnableSubmitBtn(true); })

        }
    }

    const handlePasswordChange = (event) => {
        const newPass = event.target.elements.newPassword.value;
        const newConfPass = event.target.elements.newConfPassword.value;
        if (newPass === newConfPass) {
            changePassword(newPassRequired.user, newPassRequired.data, newPass).then(() => {
                setNewPasswordRequired(null);
                setPasswordFieldType('password');
                setNewPasswordFieldType('password');
                setNewConfPasswordFieldType('password');
            }, (err) => { console.error(err) })
        } else { setConfPassError(true) }
        setEnableSubmitBtn(true);
        // if (newPass === newConfPass) {
        //     alert('new pass : ' + newPass);
        // } else { setConfPassError(true) }
    }



    return (
        <div className='loginPage'>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <h2 style={{ fontSize: '50px', textAlign: 'center', fontWeight: 600, letterSpacing: '5px', margin: '0px 0px 10px 0px', color: 'black' }}>iMessage Capture</h2>
                {/* <h2 style={{ fontSize: '35px', textAlign: 'center', fontWeight: 400, letterSpacing: '5px', margin: '0px 0px 20px 0px', color: 'black' }}>iMessage</h2> */}
            </div>
            <h1>
                <div className="line"></div>
                {(newPassRequired) ? 'Change password' : 'Login'}
                <div className="line"></div>
            </h1>
            <form onSubmit={handleFormSubmit}>
                {!newPassRequired && <>
                    <div className="field">
                        <div className="icon">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                <path d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z" />
                            </svg>
                        </div>
                        <input type="email" name='username' placeholder='Enter your email' />
                    </div>
                    <div className="field">
                        <div className="icon" onClick={handlePasswordShowHide}>
                            {(passwordFiledType !== 'password') ? (
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                                    <path d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z" />
                                </svg>
                            ) : (
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                                    <path d="M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L525.6 386.7c39.6-40.6 66.4-86.1 79.9-118.4c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C465.5 68.8 400.8 32 320 32c-68.2 0-125 26.3-169.3 60.8L38.8 5.1zM223.1 149.5C248.6 126.2 282.7 112 320 112c79.5 0 144 64.5 144 144c0 24.9-6.3 48.3-17.4 68.7L408 294.5c8.4-19.3 10.6-41.4 4.8-63.3c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3c0 10.2-2.4 19.8-6.6 28.3l-90.3-70.8zM373 389.9c-16.4 6.5-34.3 10.1-53 10.1c-79.5 0-144-64.5-144-144c0-6.9 .5-13.6 1.4-20.2L83.1 161.5C60.3 191.2 44 220.8 34.5 243.7c-3.3 7.9-3.3 16.7 0 24.6c14.9 35.7 46.2 87.7 93 131.1C174.5 443.2 239.2 480 320 480c47.8 0 89.9-12.9 126.2-32.5L373 389.9z" />
                                </svg>
                            )}
                        </div>
                        <input type={passwordFiledType} name='password' placeholder='Enter your password' />
                    </div>
                </>}

                {newPassRequired && <>
                    <div className="field">
                        <div className="icon" onClick={handleNewPasswordShowHide}>
                            {(newPasswordFiledType !== 'password') ? (
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                                    <path d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z" />
                                </svg>
                            ) : (
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                                    <path d="M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L525.6 386.7c39.6-40.6 66.4-86.1 79.9-118.4c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C465.5 68.8 400.8 32 320 32c-68.2 0-125 26.3-169.3 60.8L38.8 5.1zM223.1 149.5C248.6 126.2 282.7 112 320 112c79.5 0 144 64.5 144 144c0 24.9-6.3 48.3-17.4 68.7L408 294.5c8.4-19.3 10.6-41.4 4.8-63.3c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3c0 10.2-2.4 19.8-6.6 28.3l-90.3-70.8zM373 389.9c-16.4 6.5-34.3 10.1-53 10.1c-79.5 0-144-64.5-144-144c0-6.9 .5-13.6 1.4-20.2L83.1 161.5C60.3 191.2 44 220.8 34.5 243.7c-3.3 7.9-3.3 16.7 0 24.6c14.9 35.7 46.2 87.7 93 131.1C174.5 443.2 239.2 480 320 480c47.8 0 89.9-12.9 126.2-32.5L373 389.9z" />
                                </svg>
                            )}
                        </div>
                        <input type={newPasswordFiledType} name='newPassword' placeholder='Enter new password' />
                    </div>
                    <div className="field">
                        <div className="icon" onClick={handleNewConfPasswordShowHide}>
                            {(newConfPasswordFiledType !== 'password') ? (
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                                    <path d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z" />
                                </svg>
                            ) : (
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                                    <path d="M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L525.6 386.7c39.6-40.6 66.4-86.1 79.9-118.4c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C465.5 68.8 400.8 32 320 32c-68.2 0-125 26.3-169.3 60.8L38.8 5.1zM223.1 149.5C248.6 126.2 282.7 112 320 112c79.5 0 144 64.5 144 144c0 24.9-6.3 48.3-17.4 68.7L408 294.5c8.4-19.3 10.6-41.4 4.8-63.3c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3c0 10.2-2.4 19.8-6.6 28.3l-90.3-70.8zM373 389.9c-16.4 6.5-34.3 10.1-53 10.1c-79.5 0-144-64.5-144-144c0-6.9 .5-13.6 1.4-20.2L83.1 161.5C60.3 191.2 44 220.8 34.5 243.7c-3.3 7.9-3.3 16.7 0 24.6c14.9 35.7 46.2 87.7 93 131.1C174.5 443.2 239.2 480 320 480c47.8 0 89.9-12.9 126.2-32.5L373 389.9z" />
                                </svg>
                            )}
                        </div>
                        <input type={newConfPasswordFiledType} name='newConfPassword' placeholder='Confirm your password' />
                    </div>
                    {confPassErr && <div className="formButton">
                        <p style={{ textAlign: 'center', color: 'red' }}>Passwords do not match. Please re-enter you password.</p>
                    </div>}
                </>}

                {isAuthErr && !newPassRequired && (
                    <div className="formButton">
                        <p style={{ textAlign: 'center', color: 'red' }}>Username or password is incorrect.</p>
                    </div>
                )}
                <div className="formButton">
                    {/* <button type='button' onClick={handleSkip}>Skip</button> */}
                    <button type='submit'>{(newPassRequired) ? 'Submit' : 'Login'}</button>
                </div>
            </form >
        </div >
    )
}
