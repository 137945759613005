import React, { useState, useEffect } from 'react';
import cx from 'classnames';

export const useLinkPreview = ({
  href,
  host, 
  fetchOptions = {
                }
}) => {
  const [data, setData] = useState()
  const [error, setError] = useState()

  useEffect(() => {
    //fetchOptions.body=JSON.stringify({q:href})
    fetch(host+'?url='+href, fetchOptions)
      .then(res => {
          if (res.status !== 200) {
            console.log(res.status)
            throw new Error();
          }
          return res.json()
        })
      .then(json => setData(json))
      .catch(e => {
        setError(e)
      })
  }, [])

  return { data, error }
}

export const LinkPreview = ({
  children,
  href,
  host,
  fetchOptions,
  external,
  ...props
}) => {
  const { data, error } = useLinkPreview({
    href,
    host,
    fetchOptions
  })

  const [visible, setVisible] = useState(true)

  return (
    <div
      className={cx('link-preview', props.className)}>
      {data && (
        <div
          className={visible ? 'visible' : 'hidden'}
          aria-hidden={true}>
          {data.image && (
            <img src={data.image} alt={data.title}/>
          )}
          <div className={visible ? 'visible' : 'hidden'}>
            <a {...props} href={href} rel="noreferrer" target="_blank">
              <h2>{data.title}</h2>
            </a>
            <p>{data.description}</p>
          </div>
        </div>
      )}

      {error && error.message && <div role="alert">{error.message}</div>}
    </div>
  )
}