/*
import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div>
  );
}

export default App;
*/
import React, { useState, useEffect, reCheck } from 'react';
import { headers } from '../App';
import axios from 'axios';
import '../Styles/Home.css'
import { logout } from '../aws/Authenticate';
import ChatPreview from './ChatPreview';
import icloudimg from '../Assets/ICloud.png'

const Home = ({ userEmail, jwtToken, reCheck }) => {
    const [enableSubmitBtn, setEnableSubmitBtn] = useState(true);
    const [initial, setInitial] = useState(true);
    const [userReserved, setUserReserved] = useState(false);
    const [loggedIn, setLoggedIn] = useState(false);
    const [connected, setConnected] = useState(false);
    const [captured, setCaptured] = useState(false);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [verificationCode, setVerificationCode] = useState('');
    const [message, setMessage] = useState('');
    const [countdownMsg, setCountdownMsg] = useState('');
    const [userId, setUserId] = useState(userEmail);
    const [twoMinTimer, setTwoMinTimer] = useState('');
    const [queueErrMsg, setQueueErrMsg] = useState('');
    const [twoMinTimerInterval, setTwoMinTimerInterval] = useState(null);
    const [popUp, setPopUp] = useState('');

    const [enableShowChat, setEnableShowChat] = useState(false);
    const [userData, setUserData] = useState(null);
    // let twoMinTimerInterval;

    const [twoMinTimerLoader, setTwoMinTimerLoader] = useState(40);
    const [showTwoMinTimerLoader, setShowTwoMinTimerLoader] = useState(false);

    const [emailAddresses, setEmailAddress] = useState([]);
    const [showLoading, setShowLoading] = useState(false);
    const [editPreferences, setEditPreferences] = useState(null);
    const [editPreferencesAddNew, setEditPreferencesAddNew] = useState(false);
    const [editPreferencesAddCntct, setEditPreferencesAddCntct] = useState('');

    let USER_ID = userEmail;

    const [userGridData, setUserGridData] = useState(null);

    useEffect(() => { setMessage('') }, [initial]);

    const fetchAndSetUserGridData = async () => {
        setShowLoading(true);

        axios.get(`OUR_BACKEND/v0.1/${userEmail}/current_user_sessions/latest`)
            .then((res) => {
                let data = res.data;
                let imessageData = (data && data.length && data[0].data && data[0].data.iMessage) ? data[0].data.iMessage : null;
                setUserGridData(imessageData);
            },
                err => { }).catch(err => { });
        try {
            const requestOptions = {
                method: 'GET'
            };
            const resp = await axios.get(`OUR_BACKEND/v0.1/users/${userEmail}/profile`, requestOptions);
            // console.log(resp);
            if (resp.status === 200) {
                setUserData({
                    username: userEmail,
                    name: resp.data.name,
                    profile_image_url: resp.data.profile_image_url,
                    profile_thumbnail_url: resp.data.profile_thumbnail_url
                });
            }

        } catch (err) { }
        setShowLoading(false);
    }
    useEffect(() => {
        fetchAndSetUserGridData();
    }, [])

    /*
    useEffect(() => {
      if (userReserved) {
        // Poll for status on mount
        // pollForStatus();
      }
    }, [userReserved]);
    */

    const pollForStatus = async () => {
        try {
            // Make API call to poll_for_status
            const response = await axios.post('/api/poll_for_status', { user_id: 'user123' });

            const result = await response.json();

            if (result.success) {
                // Proceed to next stage
                setUsername('');
                setPassword('');
                setVerificationCode('');
                setMessage('');
            } else {
                // Retry by polling again
                setTimeout(pollForStatus, 3000);
            }
        } catch (error) {
            console.error('Error polling for status:', error);
        }
    };

    async function makeGetRequest(user_id) {
        const url = `OUR_BACKEND/v0.1/users/${user_id}/imessage_sessions`;

        try {
            const response = await axios.get(url);
            return response;
        } catch (error) {
            console.error(`Error making GET request: ${error.message}`);
            throw error; // Propagate the error to the caller if needed
        }
    }

    async function makePutRequest(user_id) {
        const url = `OUR_BACKEND/v0.1/users/${user_id}/imessage_sessions`;
        const currentTimeInMillis = new Date().getTime(); // Get current time in milliseconds

        const payload = { status: 'requested', updated_time: currentTimeInMillis };
        const headers = { 'Content-Type': 'application/json' };

        try {
            const response = await axios.put(url, payload, headers);
            return response;
        } catch (error) {
            console.error(`Error making PUT request: ${error.message}`);
            throw error; // Propagate the error to the caller if needed
        }
    }

    async function pollForStatusHelper(user_id, timeout = 120) {
        const startTime = Date.now();
        let lastPollTime = startTime;

        // Make the initial PUT request
        console.log(`Requesting resource for ${user_id}`);
        const putResponse = await makePutRequest(user_id);
        if (putResponse.status !== 200) {
            console.error(`Error making PUT request: ${putResponse.statusText}`);
            return false;
        }
        setShowLoading(true);
        startTwoMinTimerLoader();
        // let exp = 0;
        while (true) {
            // if (exp > 10) { await stopTwoMinTimerLoader(); return true; }
            // else exp++;
            // Make the first GET request
            const getResponse = await makeGetRequest(user_id);
            if (getResponse.status !== 200) {
                console.error(`Error making GET request: ${getResponse.statusText}`);
                await stopTwoMinTimerLoader();
                return false;
            }

            const statusData = await getResponse.data;
            const status = statusData.status;

            if (status === 'allocated') {
                console.log('Allocation successful!');
                await stopTwoMinTimerLoader();
                return true;
            } else if (status === 'connection-failed') {
                setPopUp('All Resources are processing other Linked Device Capture requests. Try again in a few minutes');
                setTimeout(popUpClose, 5000);
                return false;
            }

            const elapsedTime = Date.now() - startTime;
            const timeSinceLastPoll = Date.now() - lastPollTime;

            if (elapsedTime >= timeout * 1000) {
                setCountdownMsg('Requested for resource timed out. Try again later.');
                console.log('Timeout reached. Allocation unsuccessful.');
                await stopTwoMinTimerLoader();
                return false;
            }

            if (timeSinceLastPoll > 1) {
                const countdown = Math.ceil((timeout * 1000 - elapsedTime) / 1000);
                // console.log(`Polling... Time left: ${countdown} seconds`);
                setCountdownMsg('Requesting Compute Resource for Linked Device Capture Session. Prepare to enter your iCloud ID and Password. Allocation Time Remaining.');
                lastPollTime = Date.now();
            }

            await new Promise(resolve => setTimeout(resolve, 1000));
        }
        setShowLoading(false);
    }

    const handleStartNow = async () => {
        setMessage('');
        try {
            popUpClose();
            const resp = await axios.get(`OUR_BACKEND/v0.1/${userEmail}/checkqueueavailable`)
            if (resp.status === 200) {
                if (enableSubmitBtn) {
                    setEnableSubmitBtn(false);
                    // Make API call to reserve instance
                    try {
                        const isAllocated = await pollForStatusHelper(userId);
                        // await new Promise(resolve => setTimeout(resolve, 1000));
                        // const isAllocated = true;

                        if (isAllocated) {
                            setInitial((prev) => false);
                            setUserReserved((prev) => true);
                            startTwoMinTimer(sessionTerminate);
                        } else {
                            console.error('Error reserving instance:');
                        }
                        setCountdownMsg('');
                    } catch (error) {
                        console.error('Error reserving instance:', error);
                    }
                    setEnableSubmitBtn(true);
                    setQueueErrMsg('');
                }
            } else { setQueueErrMsg('All Resources are processing other Linked Device Capture requests. Try again in a few minutes'); }
        } catch (err) { setQueueErrMsg('All Resources are processing other Linked Device Capture requests. Try again in a few minutes'); }
    };

    async function authenticateUserHelper(user_id, username, password) {
        let tempCondition = true;

        while (tempCondition) {

            // Replace '<user_id>' and 'https://example.com' with your actual values
            const url = `OUR_BACKEND/v0.1/users/${user_id}/imessage_sessions`;

            // Prepare the payload for the PUT request
            const payload = { status: 'login', tmp1: username, tmp2: password };

            // Make the HTTP PUT request
            try {
                const response = await axios.put(url, payload);

                // Check the response status
                if (response.status === 200) {
                    console.log('Login attempt sent...');
                    let twoMinTempInterval = null;

                    // Make HTTP GET request to retrieve 'status' JSON field value
                    while (tempCondition) {
                        if (!twoMinTempInterval) twoMinTempInterval = setTimeout(() => { tempCondition = false; }, 120000)

                        await new Promise(resolve => setTimeout(resolve, 5000)); // 5 seconds delay
                        const responseGet = await axios.get(url);

                        // return true;

                        try {
                            const statusValue = responseGet.data.status;
                            // console.log(tempCondition);

                            if (statusValue === 'code-req') {
                                console.log('Successfully logged in.');
                                return true;
                            } else if (statusValue === 'login-failed') {
                                console.log('Incorrect username/password; please try again...');
                                return false;
                            } else if (statusValue === 'login-error') {
                                setPopUp('<a>Error:</a> Apple has not accepted your iCloud ID or Password, try again after a few minutes or contact the Support Team.');
                                setTimeout(popUpClose, 5000);
                                return null;
                            } else {
                                console.log(`Unexpected 'status' value: ${statusValue}; wait for 5 more seconds...`);
                                // return false;
                            }
                        } catch (error) {
                            console.log('Failed to parse JSON response.');
                            setPopUp('<a>Error:</a> Apple has not accepted your iCloud ID or Password, try again after a few minutes or contact the Support Team.');
                            setTimeout(popUpClose, 5000);
                            return false;
                        }
                    }
                } else {
                    console.error(`Login attempt couldn't be sent. Status code: ${response.status}, Response: ${response.data}`);
                    return false;
                }
            } catch (error) {
                console.error(`Error making PUT request: ${error.message}`);
                // Handle the error as needed, e.g., return or throw
                return false;
            }
        }
    }

    const handleAuthenticate = async () => {
        if (enableSubmitBtn) {
            if (username && password) {
                console.log('clicked')
                setEnableSubmitBtn(false);
                // Make API call to authenticate user
                console.log(await stopTwoMinTimer());
                try {
                    setShowLoading(true);
                    // startTwoMinTimer(sessionTerminate);
                    startTwoMinTimerLoader();
                    setMessage('Submitting the username and password...');
                    const authenticated = await authenticateUserHelper(userId, username, password);
                    // console.log(await stopTwoMinTimer());
                    await stopTwoMinTimerLoader();
                    // setShowLoading(false);
                    // await new Promise(resolve => setTimeout(resolve, 1000));
                    // const authenticated = true;

                    if (authenticated) {
                        // Proceed to next stage
                        setVerificationCode('');
                        setMessage('');
                        setLoggedIn((prev) => true);
                        setUserReserved((prev) => false);
                        startTwoMinTimer(sessionTerminate);
                        setMessage('Username and password were correct!');
                    } else if (authenticated === false) {
                        // Display incorrect username and password message
                        startTwoMinTimer(sessionTerminate);
                        setMessage('Error incorrect iCloud ID or Password, try again');
                    }
                } catch (error) {
                    console.error('Error authenticating user:', error);
                }
            }
        } else {
            startTwoMinTimer(sessionTerminate);
            setMessage('Error incorrect iCloud ID or Password, try again');
        }
        setEnableSubmitBtn(true);
    };

    async function submitVerificationCode(user_id, code) {
        // await new Promise((res, rej) => { setTimeout(() => { res() }, 5000) })
        // return true;
        while (true) {
            // Replace '<user_id>' and 'https://example.com' with your actual values
            const url = `OUR_BACKEND/v0.1/users/${user_id}/imessage_sessions`;

            // Prepare the payload for the PUT request
            const payload = { status: 'code', tmp3: code };

            // Make the HTTP PUT request
            try {
                const response = await axios.put(url, payload);

                // Check the response status
                if (response.status === 200) {
                    console.log('Verification submitted');

                    // Make HTTP GET request to retrieve 'status' JSON field value
                    while (true) {
                        await new Promise(resolve => setTimeout(resolve, 5000)); // 5 seconds delay
                        const responseGet = await axios.get(url);

                        try {
                            const statusValue = responseGet.data.status;

                            // return true;


                            if (statusValue === 'connected') {
                                console.log('Successfully verified in.');
                                return true;
                            } else if (statusValue === 'code-req') {
                                console.log('Error: Incorrect Verification Code, Try Again.');
                                return false;
                            } else if (statusValue === 'code-unexpected-error') {
                                setPopUp('<a>Error:</a> Apple has not accepted your Verification Code, try again after a few minutes or contact the Support Team.');
                                setTimeout(popUpClose, 5000);
                                return null;
                            } else {
                                console.log(`Unexpected 'status' value: ${statusValue}; wait for 5 more seconds...`);
                            }
                        } catch (error) {
                            console.log('Failed to parse JSON response.');
                            setPopUp('<a>Error:</a> Apple has not accepted your Verification Code, try again after a few minutes or contact the Support Team.');
                            setTimeout(popUpClose, 5000);
                            return false;
                        }
                    }
                } else {
                    console.error(`Verification submission failed. Status code: ${response.status}, Response: ${response.data}`);
                    return false;
                }
            } catch (error) {
                console.error(`Error making PUT request: ${error.message}`);
                // Handle the error as needed, e.g., return or throw
                return false;
            }
        }
    }

    const handleSubmitVerificationCode = async () => {
        if (enableSubmitBtn) {
            setEnableSubmitBtn(false);
            if (verificationCode) {
                // Make API call to submit verification code
                await stopTwoMinTimer();
                try {
                    setShowLoading(true);
                    setMessage('Submitting verification code...');
                    // startTwoMinTimer(sessionTerminate);
                    startTwoMinTimerLoader();
                    const verified = await submitVerificationCode(userId, verificationCode);
                    // await stopTwoMinTimer();
                    // setShowLoading(false);
                    await stopTwoMinTimerLoader();

                    if (verified) {
                        // Display congratulation message
                        setMessage('Connection is complete! Send some test messages to be captured.');

                        // Ask the user to send test messages
                        // ...
                        setLoggedIn((prev) => false);
                        setConnected((prev) => true);

                        console.log(
                            'initial : ' + initial +
                            ', userReserved : ' + userReserved +
                            ', loggedIn : ' + loggedIn +
                            ', connected : ' + connected +
                            ', captured : ' + captured
                        )
                        startTwoMinTimerForSubmitCapture(handleSubmitCapture);

                    } else {
                        // Display incorrect verification code message
                        setMessage('Error: Incorrect Verification Code, Try Again.');
                        startTwoMinTimer(sessionTerminate);
                    }
                } catch (error) {
                    console.error('Error submitting verification code:', error);
                }
            } else {
                setMessage('Error: Incorrect Verification Code, Try Again.');
                startTwoMinTimer(sessionTerminate);
            }
            setEnableSubmitBtn(true);
        }
    };

    async function submitCapture(user_id) {
        while (true) {
            // Replace '<user_id>' and 'https://example.com' with your actual values
            const url = `OUR_BACKEND/v0.1/users/${user_id}/imessage_sessions`;

            // Prepare the payload for the PUT request
            const payload = { status: 'export' };

            // Make the HTTP PUT request
            try {
                const response = await axios.put(url, payload);

                // Check the response status
                if (response.status === 200) {
                    console.log('Export request submitted');

                    // Make HTTP GET request to retrieve 'status' JSON field value
                    while (true) {
                        await new Promise(resolve => setTimeout(resolve, 5000)); // 5 seconds delay
                        const responseGet = await axios.get(url);

                        try {
                            const statusValue = responseGet.data.status;

                            if (statusValue === 'export-done') {
                                console.log('Successfully exported the messages.');
                                return true;
                            } else if (statusValue === 'export-failed') {
                                console.log('Export failed; please try again later...');
                                return false;
                            } else {
                                console.log(`Unexpected 'status' value: ${statusValue}; wait for 5 more seconds...`);
                                // return true;
                            }
                        } catch (error) {
                            console.log('Failed to parse JSON response.');
                            return false;
                        }
                    }
                } else {
                    console.error(`Export request submission failed. Status code: ${response.status}, Response: ${response.data}`);
                    return false;
                }
            } catch (error) {
                console.error(`Error making PUT request: ${error.message}`);
                // Handle the error as needed, e.g., return or throw
                return false;
            }
        }
    }

    const handleSubmitCapture = async () => {
        if (enableSubmitBtn) {
            setEnableSubmitBtn(false);
            await stopTwoMinTimer();
            // Make API call to submit verification code
            try {
                setShowLoading(true);
                setMessage('Submitting capture request...');
                // startTwoMinTimerLoader();
                const captured = await submitCapture(userId);
                // await stopTwoMinTimerLoader();
                setShowLoading(false);

                if (captured) {
                    // Display congratulation message
                    // openNewTab();
                    setMessage('');

                    // Ask the user to send test messages
                    // ...
                    setInitial((prev) => true);
                    setUserReserved((prev) => false);
                    setLoggedIn((prev) => false);
                    setConnected((prev) => false);

                    console.log(
                        'initial : ' + initial +
                        ', userReserved : ' + userReserved +
                        ', loggedIn : ' + loggedIn +
                        ', connected : ' + connected +
                        ', captured : ' + captured
                    )
                    setCaptured(false);
                    // stopTwoMinTimer();
                    setTwoMinTimer('');
                    setCountdownMsg('');
                    setEnableSubmitBtn(true);
                    fetchAndSetUserGridData();
                } else {
                    setMessage('Could not capture the messages. Please try again later.');
                }
            } catch (error) {
                console.error('Error submitting verification code:', error);
            }
            setEnableSubmitBtn(true);
        }
    };

    const openNewTab = () => {
        const url = `https://chatviewer.d3pqq82x99f4r.amplifyapp.com/?token=${jwtToken}&username=${userEmail}`;
        // const url = `OUR_BACKEND:3001/LoginWithToken/?token=${jwtToken}&username=${userEmail}`;
        window.open(url, '_blank');
    };

    // Initial list of email addresses
    // const emailAddresses = ['select email address', 'jscottwhitney@gmail.com', 'jae@sausalitolabs.com']; //'sms@menloadvisors.com',
    // const fetchEmailAddress = async () => {
    //     const url = `OUR_BACKEND/v0.1/users/${userId}/imessage_sessions`;
    //     try {
    //         const response = await axios.get(url);
    //         setEmailAddress(response.data);
    //         // console.log(response.data);
    //         setUserData(response.data);
    //     } catch (error) {
    //         console.error(`Error making GET request: ${error.message}`);
    //         // throw error; // Propagate the error to the caller if needed
    //     }
    // }
    // useEffect(() => {
    //     if (emailAddresses.length === 0) {
    //         fetchEmailAddress();
    //     }
    // }, [emailAddresses])

    // Event handler for dropdown change
    const handleDropdownChange = (event) => {
        setUserId(event.target.value);
        USER_ID = event.target.value;
        console.log(`USER_ID set to: ${USER_ID}`);
    };

    const localApiTest = async () => {
        const url = 'OUR_BACKEND/testApi';
        try {
            const response = await axios.get(url);
            return response;
        } catch (error) {
            console.error(`Error making GET request: ${error.message}`);
            throw error; // Propagate the error to the caller if needed
        }
    }

    function formatLocalTimeUptoMinutes(milliseconds) {
        const minutes = milliseconds / (1000 * 60); // Convert milliseconds to minutes
        const hours = Math.floor(minutes / 60); // Calculate the hours
        const remainingMinutes = Math.floor(minutes % 60); // Calculate the remaining minutes
        const date = new Date(0); // Create a new Date object with the epoch time (0 milliseconds)
        date.setUTCHours(hours);
        date.setUTCMinutes(remainingMinutes);
        return date.toLocaleString();
    }
    function getStatusColor(item) {
        if (item.messages_captured > 0 && item.session_saved) {
            return 'green';
        } else if ((item.messages_captured === 0 || item.messages_captured === null) && item.session_saved) {
            // return 'amber';
            return 'green';
        } else {
            return 'red';
        }
    }


    const startTwoMinTimer = async (timeOutFunction) => {
        await stopTwoMinTimer();
        let countdown = 120;
        let timer = new Date();
        timer.setHours(0, 0, 0, 0);
        timer = new Date(timer.getTime() + countdown * 1000);
        setTwoMinTimer(`Expires in : ${timer.getMinutes()}:${timer.getSeconds()}`);
        const newIntervalval = setInterval(async () => {
            timer.setHours(0, 0, 0, 0);
            timer = new Date(timer.getTime() + countdown * 1000);
            // console.log(`Expires in : ${timer.getMinutes()}:${timer.getSeconds()}`);
            setTwoMinTimer(`Expires in : ${timer.getMinutes()}:${timer.getSeconds()}`);
            if (countdown === 0) {
                await sessionTerminate();
                setConnected((prev) => false);
                setInitial((prev) => true);
                setUserReserved((prev) => false);
                setLoggedIn((prev) => false);
                setCaptured((prev) => false);
                // stopTwoMinTimer();
                clearInterval(newIntervalval);
                setTwoMinTimer('');
                setCountdownMsg('');
                setEnableSubmitBtn(true);
                fetchAndSetUserGridData();
                await stopTwoMinTimer();

                console.log(
                    'initial : ' + initial +
                    ', userReserved : ' + userReserved +
                    ', loggedIn : ' + loggedIn +
                    ', connected : ' + connected +
                    ', captured : ' + captured
                )

            }
            countdown--;
        }, 1000);
        setTwoMinTimerInterval(prev => { if (prev) clearInterval(prev); return newIntervalval; });
    }

    const startTwoMinTimerForSubmitCapture = async (timeOutFunction, countdown = 120) => {
        await stopTwoMinTimer();
        // let countdown = 120;
        let timer = new Date();
        timer.setHours(0, 0, 0, 0);
        timer = new Date(timer.getTime() + countdown * 1000);
        setTwoMinTimer(`Expires in : ${timer.getMinutes()}:${timer.getSeconds()}`);
        const newIntervalval = setInterval(async () => {
            timer.setHours(0, 0, 0, 0);
            timer = new Date(timer.getTime() + countdown * 1000);
            // console.log(`Expires in : ${timer.getMinutes()}:${timer.getSeconds()}`);
            setTwoMinTimer(`Expires in : ${timer.getMinutes()}:${timer.getSeconds()}`);
            if (countdown === 0) {
                clearInterval(newIntervalval);
                await handleSubmitCapture();
            }
            countdown--;
        }, 1000);
        setTwoMinTimerInterval(prev => { if (prev) clearInterval(prev); return newIntervalval; });
    }
    const stopTwoMinTimer = () => {
        return new Promise((resolve, reject) => {
            try {
                setTwoMinTimerInterval(prev => { if (prev) clearInterval(prev); return null });
            } catch (err) { console.log(err); reject(err) }
            setTwoMinTimer('');
            resolve('Cleared');
        })
    }


    const startTwoMinTimerLoader = async () => {
        await stopTwoMinTimer();
        setShowTwoMinTimerLoader(true);
        setTwoMinTimerLoader(0);
        let countdown = 1;
        const finalCount = 120;
        let countForLoader = 1;
        const newIntervalval = setInterval(async () => {
            if (countdown === finalCount) {
                clearInterval(newIntervalval);
            } else {
                if (countdown <= 60) countForLoader += 1.5;
                else countForLoader += 0.5;
                setTwoMinTimerLoader((countForLoader / 120) * 100);
                countdown++;
            }
        }, 1000);
        setTwoMinTimerInterval(prev => { if (prev) clearInterval(prev); return newIntervalval; });
    }

    const stopTwoMinTimerLoader = async () => {
        setTwoMinTimerLoader(100);
        await stopTwoMinTimer();
        await new Promise((res) => { setTimeout(() => { setShowTwoMinTimerLoader(false); setShowLoading(false); res(); }, 1500); });
    }


    const getNext11PM = (currDate) => {
        const now = new Date();

        const utcTimestamp = now.getTime() + (now.getTimezoneOffset() * 60000);

        const next4AMGMT = Math.ceil(utcTimestamp / (24 * 60 * 60 * 1000)) * (24 * 60 * 60 * 1000) + (4 * 60 * 60 * 1000);

        const next4AMGMTDate = new Date(next4AMGMT);

        return formatLocalTimeUptoMinutes(next4AMGMTDate.getTime());
    }

    const handleEditPreferences = async () => {
        setShowLoading(true);
        try {
            // let roomIds = [];
            const resp = await axios.get(`OUR_BACKEND/v0.1/users/${userEmail}/imessage_sessions_preferences`);
            setEditPreferences(resp.data);
            setShowLoading(false);
        } catch (err) { setShowLoading(false); }
    }
    const handleEditPreferencesChechUncheck = (e) => {
        const { name, checked } = e.target;
        // console.log(name);
        // console.log(checked);
        let newPreferences = [];

        if (name === 'Select all') { newPreferences = editPreferences.map(row => { return { ...row, is_active: checked ? 1 : 0 } }) }
        else { newPreferences = editPreferences.map(row => (row.user === name) ? { ...row, is_active: checked ? 1 : 0 } : row) }
        setEditPreferences(newPreferences);
    }
    const handleEditPreferencesSubmit = async () => {
        setShowLoading(true);
        try {
            // const userSelectedPreferences = editPreferences.filter(row => row?.is_active === 1 || false);
            // console.log(userSelectedPreferences);
            // if (userSelectedPreferences.length > 0) {
            const resp = await axios.post(`OUR_BACKEND/v0.1/users/${userEmail}/imessage_sessions_preferences`, editPreferences);
            // console.log(resp);
            setEditPreferences(null);
            // }
            setShowLoading(false);
        } catch (err) { setEditPreferences(null); setShowLoading(false); }
    }
    const handleLogout = () => {
        logout();
        reCheck({
            username: '',
            tokenData: null
        });
    }

    const editPreferencesAddNewClose = () => {
        setEditPreferencesAddCntct('');
        setEditPreferencesAddNew(false);
    }

    const editPreferencesAddNewSubmit = (e, submit = false) => {
        // console.log(e.keyCode)
        e.preventDefault();
        if (!editPreferencesAddNew) { setEditPreferencesAddNew(true) }
        else if (editPreferencesAddCntct && (e.keyCode === 13 || (submit && editPreferencesAddNew))) {
            setEditPreferences(prev => [...prev, { id: (Date.now() + ''), user: editPreferencesAddCntct, is_active: 1 }])
            // console.log(editPreferencesAddCntct);
            editPreferencesAddNewClose();
        }
    }
    const editPreferencesHandleDelete = (id) => {
        setEditPreferences(editPreferences.map(row => (row.id === id) ? { ...row, is_active: (row.is_active === -1) ? 0 : -1 } : row))
    }

    const popUpClose = () => {
        setPopUp('');
        setInitial((prev) => true);
        setUserReserved((prev) => false);
        setLoggedIn((prev) => false);
        setConnected((prev) => false);
        setCaptured((prev) => false);
        fetchAndSetUserGridData();
        setEnableSubmitBtn(true);
        setTwoMinTimer('');
        setCountdownMsg('');
        sessionTerminate();

        console.log(
            'initial : ' + initial +
            ', userReserved : ' + userReserved +
            ', loggedIn : ' + loggedIn +
            ', connected : ' + connected +
            ', captured : ' + captured
        )
    }

    const sessionTerminate = async () => {
        if (userId) {
            try {
                // Replace '<user_id>' and 'https://example.com' with your actual values
                const url = `OUR_BACKEND/v0.1/users/${userId}/imessage_sessions`;

                // Prepare the payload for the PUT request
                const payload = { status: 'session_timeout', tmp1: username, tmp2: password };
                const response = await axios.put(url, payload);
                if (response.status === 200) {
                    console.log("Session Terminated");
                }
            } catch (err) {
                console.error("Error in session terminates", err);
            }
        }
    }




    return (
        <div className='home'>
            <nav style={{ position: enableShowChat ? 'relative' : 'fixed' }}>
                <div className="left"><h2>Sausalito Labs</h2></div>
                <div className="right">
                    <div className="col">
                        {userEmail}
                        {/* <button onClick={handleLogout}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                <path d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V256c0 17.7 14.3 32 32 32s32-14.3 32-32V32zM143.5 120.6c13.6-11.3 15.4-31.5 4.1-45.1s-31.5-15.4-45.1-4.1C49.7 115.4 16 181.8 16 256c0 132.5 107.5 240 240 240s240-107.5 240-240c0-74.2-33.8-140.6-86.6-184.6c-13.6-11.3-33.8-9.4-45.1 4.1s-9.4 33.8 4.1 45.1c38.9 32.3 63.5 81 63.5 135.4c0 97.2-78.8 176-176 176s-176-78.8-176-176c0-54.4 24.7-103.1 63.5-135.4z" />
                            </svg>
                            Logout
                        </button> */}
                    </div>
                    <details className='settings'>
                        <summary>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                <path d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z" />
                            </svg>
                        </summary>
                        <ul>
                            <div className="sqr"></div>
                            <li>
                                <button onClick={handleEditPreferences}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                        <path d="M495.9 166.6c3.2 8.7 .5 18.4-6.4 24.6l-43.3 39.4c1.1 8.3 1.7 16.8 1.7 25.4s-.6 17.1-1.7 25.4l43.3 39.4c6.9 6.2 9.6 15.9 6.4 24.6c-4.4 11.9-9.7 23.3-15.8 34.3l-4.7 8.1c-6.6 11-14 21.4-22.1 31.2c-5.9 7.2-15.7 9.6-24.5 6.8l-55.7-17.7c-13.4 10.3-28.2 18.9-44 25.4l-12.5 57.1c-2 9.1-9 16.3-18.2 17.8c-13.8 2.3-28 3.5-42.5 3.5s-28.7-1.2-42.5-3.5c-9.2-1.5-16.2-8.7-18.2-17.8l-12.5-57.1c-15.8-6.5-30.6-15.1-44-25.4L83.1 425.9c-8.8 2.8-18.6 .3-24.5-6.8c-8.1-9.8-15.5-20.2-22.1-31.2l-4.7-8.1c-6.1-11-11.4-22.4-15.8-34.3c-3.2-8.7-.5-18.4 6.4-24.6l43.3-39.4C64.6 273.1 64 264.6 64 256s.6-17.1 1.7-25.4L22.4 191.2c-6.9-6.2-9.6-15.9-6.4-24.6c4.4-11.9 9.7-23.3 15.8-34.3l4.7-8.1c6.6-11 14-21.4 22.1-31.2c5.9-7.2 15.7-9.6 24.5-6.8l55.7 17.7c13.4-10.3 28.2-18.9 44-25.4l12.5-57.1c2-9.1 9-16.3 18.2-17.8C227.3 1.2 241.5 0 256 0s28.7 1.2 42.5 3.5c9.2 1.5 16.2 8.7 18.2 17.8l12.5 57.1c15.8 6.5 30.6 15.1 44 25.4l55.7-17.7c8.8-2.8 18.6-.3 24.5 6.8c8.1 9.8 15.5 20.2 22.1 31.2l4.7 8.1c6.1 11 11.4 22.4 15.8 34.3zM256 336a80 80 0 1 0 0-160 80 80 0 1 0 0 160z" />
                                    </svg>
                                    <label>
                                        Manage Preferences
                                    </label>
                                </button>
                            </li>
                            <li>
                                <button onClick={handleLogout}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                        <path d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V256c0 17.7 14.3 32 32 32s32-14.3 32-32V32zM143.5 120.6c13.6-11.3 15.4-31.5 4.1-45.1s-31.5-15.4-45.1-4.1C49.7 115.4 16 181.8 16 256c0 132.5 107.5 240 240 240s240-107.5 240-240c0-74.2-33.8-140.6-86.6-184.6c-13.6-11.3-33.8-9.4-45.1 4.1s-9.4 33.8 4.1 45.1c38.9 32.3 63.5 81 63.5 135.4c0 97.2-78.8 176-176 176s-176-78.8-176-176c0-54.4 24.7-103.1 63.5-135.4z" />
                                    </svg>
                                    <label>
                                        Logout
                                    </label>
                                </button>
                            </li>
                        </ul>
                    </details>
                </div>
            </nav>
            {!enableShowChat && <>
                <div className="homeCard" style={{ height: initial ? '70vh' : '100vh' }}>
                    <h1 style={{ marginBottom: '50px' }}>iMessage Capture Setup Page</h1>

                    {initial && userGridData && <div className='table'>
                        <div className="col">
                            <div className="row"><h3>STATUS</h3></div>
                            <div className="row">
                                <div className="ball"
                                    style={{ backgroundColor: getStatusColor(userGridData) }}
                                ></div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="row"><h3>LAST CAPTURE</h3></div>
                            <div className="row">{userGridData.session_saved ? formatLocalTimeUptoMinutes(Number(userGridData.inserted_time)) : 'N/A'}</div>
                        </div>
                        <div className="col">
                            <div className="row"><h3>CAPTURE COUNT</h3></div>
                            <div className="row">{(userGridData.messages_captured_new) ? (userGridData.messages_captured_new) : 'N/A'}</div>
                        </div>
                        <div className="col">
                            <div className="row"><h3>NEXT CAPTURE</h3></div>
                            <div className="row">{userGridData.session_saved ? getNext11PM(userGridData.inserted_time) : 'No (Needs re-linking)'}</div>
                        </div>
                    </div>}
                    {(!initial && !connected) && <div className="nextContentCard">

                        {userReserved && (
                            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                                <br />
                                <p>Enter iCloud ID and Password to initiate iMessage Linked Device Capture Session</p>
                                <br />
                                <div>
                                    <label className='authInputLabel'>iCloud ID : </label>
                                    <input className='authInput' type="text" value={username} onChange={(e) => setUsername(e.target.value)} />
                                </div>
                                <br />
                                <div>
                                    <label className='authInputLabel'>Password : </label>
                                    <input className='authInput' type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                                </div>
                                <br />
                                <div className="startNowBtn">
                                    <button style={{ cursor: (enableSubmitBtn && username && password) ? 'pointer' : 'not-allowed' }}
                                        onClick={handleAuthenticate}>{userGridData ? 'Reinitiate session' : 'Authenticate'}</button>
                                </div>
                                {message && (<>
                                    <br />
                                    <br /><div>{message}</div>
                                </>)}
                                <br />
                            </div>
                        )}
                        {loggedIn && (
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <br />
                                <div className="icloudCard">
                                    <img src={icloudimg} alt="iCloud logo" />
                                    <p>Your Apple ID is being used to sign in to a new device.
                                        Enter your verification code from Apple  to authorized and initiate your Linked Device Session.</p>
                                </div>
                                <br />
                                <div className='icloudCardInp'>
                                    <input type="text" value={verificationCode} onChange={(e) => setVerificationCode(e.target.value)} />
                                </div>
                                <br />
                                <br></br>
                                <div className="startNowBtn">
                                    <button style={{ cursor: (enableSubmitBtn && verificationCode) ? 'pointer' : 'not-allowed' }}
                                        onClick={handleSubmitVerificationCode}>Submit Verification Code</button>
                                </div>
                                <br></br>
                                <br></br><div>{message}</div>
                            </div>
                        )}
                        {twoMinTimer && <p>{twoMinTimer}</p>}
                    </div>}
                    {connected && (
                        <div>
                            <p style={{ margin: '0px 50px 70px 50px', textAlign: 'center', width: '400px' }}>Your Apple ID has been accepted, and your Linked Device Capture Session is now  Active. Send some test messages to be captured, then select Capture Now.</p>
                            <div className="startNowBtn">
                                <button style={{ cursor: (enableSubmitBtn) ? 'pointer' : 'not-allowed' }}
                                    onClick={handleSubmitCapture}>Capture Now</button>
                            </div>
                            <br></br>
                            <br></br>
                            <div>{message}</div>
                        </div>
                    )}
                    {twoMinTimer && connected && <p>{twoMinTimer}</p>}


                </div>
                {initial && <div className="cardContainer">

                    <div className="card" onClick={handleStartNow}>
                        <div className="icon" style={{ backgroundColor: '#3cbaf8' }}>
                            {userGridData ?
                                (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                    <path d="M105.1 202.6c7.7-21.8 20.2-42.3 37.8-59.8c62.5-62.5 163.8-62.5 226.3 0L386.3 160H352c-17.7 0-32 14.3-32 32s14.3 32 32 32H463.5c0 0 0 0 0 0h.4c17.7 0 32-14.3 32-32V80c0-17.7-14.3-32-32-32s-32 14.3-32 32v35.2L414.4 97.6c-87.5-87.5-229.3-87.5-316.8 0C73.2 122 55.6 150.7 44.8 181.4c-5.9 16.7 2.9 34.9 19.5 40.8s34.9-2.9 40.8-19.5zM39 289.3c-5 1.5-9.8 4.2-13.7 8.2c-4 4-6.7 8.8-8.1 14c-.3 1.2-.6 2.5-.8 3.8c-.3 1.7-.4 3.4-.4 5.1V432c0 17.7 14.3 32 32 32s32-14.3 32-32V396.9l17.6 17.5 0 0c87.5 87.4 229.3 87.4 316.7 0c24.4-24.4 42.1-53.1 52.9-83.7c5.9-16.7-2.9-34.9-19.5-40.8s-34.9 2.9-40.8 19.5c-7.7 21.8-20.2 42.3-37.8 59.8c-62.5 62.5-163.8 62.5-226.3 0l-.1-.1L125.6 352H160c17.7 0 32-14.3 32-32s-14.3-32-32-32H48.4c-1.6 0-3.2 .1-4.8 .3s-3.1 .5-4.6 1z" />
                                </svg>)
                                :
                                (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                                    <path d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z" />
                                </svg>)
                            }
                        </div>
                        <h2>{userGridData ? 'Reinitiate' : 'Start'}</h2>
                        <p>
                            Select to {userGridData ? 'Reinitiate' : 'Initiate'} Linked Device Capture Session
                        </p>
                    </div>

                    <div className="card" style={{ cursor: (userData) ? 'pointer' : 'not-allowed' }} onClick={() => { if (userData) setEnableShowChat(true) }}>
                        <div className="icon" style={{ backgroundColor: '#f86f71' }}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                                <path d="M208 352c114.9 0 208-78.8 208-176S322.9 0 208 0S0 78.8 0 176c0 38.6 14.7 74.3 39.6 103.4c-3.5 9.4-8.7 17.7-14.2 24.7c-4.8 6.2-9.7 11-13.3 14.3c-1.8 1.6-3.3 2.9-4.3 3.7c-.5 .4-.9 .7-1.1 .8l-.2 .2 0 0 0 0C1 327.2-1.4 334.4 .8 340.9S9.1 352 16 352c21.8 0 43.8-5.6 62.1-12.5c9.2-3.5 17.8-7.4 25.3-11.4C134.1 343.3 169.8 352 208 352zM448 176c0 112.3-99.1 196.9-216.5 207C255.8 457.4 336.4 512 432 512c38.2 0 73.9-8.7 104.7-23.9c7.5 4 16 7.9 25.2 11.4c18.3 6.9 40.3 12.5 62.1 12.5c6.9 0 13.1-4.5 15.2-11.1c2.1-6.6-.2-13.8-5.8-17.9l0 0 0 0-.2-.2c-.2-.2-.6-.4-1.1-.8c-1-.8-2.5-2-4.3-3.7c-3.6-3.3-8.5-8.1-13.3-14.3c-5.5-7-10.7-15.4-14.2-24.7c24.9-29 39.6-64.7 39.6-103.4c0-92.8-84.9-168.9-192.6-175.5c.4 5.1 .6 10.3 .6 15.5z" />
                            </svg>
                        </div>
                        <h2>Show Chats</h2>
                        <p>Select to show previously captured.</p>
                    </div>

                </div>}






                {showLoading && (
                    <div className="loading">
                        {!showTwoMinTimerLoader && (
                            <>
                                <div className="load"></div>
                                <h3>Loading please wait...</h3>
                            </>
                        )}
                        {showTwoMinTimerLoader && (
                            <>
                                <br />
                                <span className="twoMinLoader">
                                    <div style={{ width: (twoMinTimerLoader + '%') }}></div>
                                </span>
                                <br />
                                <label>{Math.round(twoMinTimerLoader)}%</label>
                            </>
                        )}
                        {countdownMsg && (<p>{countdownMsg}</p>)}
                        {message && (<p>{message}</p>)}
                        {twoMinTimer && (<p>{twoMinTimer}</p>)}
                    </div>
                )}
                {queueErrMsg && (
                    <div className='queueErrMsg'>
                        <div className="card">
                            <p>{queueErrMsg}</p>
                            <button onClick={() => { setQueueErrMsg('') }}>Okay</button>
                        </div>
                    </div>
                )}
                {editPreferences && (
                    <div className="editPreferences">
                        <div className="card">
                            <div className="fieldBlk">
                                <h3>EDIT PREFERENCES</h3>
                            </div>
                            <form onSubmit={(e) => { e.preventDefault(); }}>

                                <div className="fieldSlct">
                                    <div className="left">
                                        <input type="checkbox" name="Select all" id=""
                                            onChange={handleEditPreferencesChechUncheck} checked={editPreferences.filter(row => row.is_active !== 1).length === 0} />
                                        <label>SELECT ALL</label>
                                    </div>
                                    <div className="right">
                                        {editPreferencesAddNew && <input type="text" placeholder='Your new contact' onChange={(e) => { setEditPreferencesAddCntct(e.currentTarget.value) }}
                                            value={editPreferencesAddCntct} onKeyUp={editPreferencesAddNewSubmit} />}
                                        <div className="icon">
                                            <button type='button' style={{ backgroundColor: editPreferencesAddNew ? 'rgb(187, 255, 187)' : 'black' }}
                                                title={editPreferencesAddNew ? 'Done' : 'Add new contact'} onClick={(e) => { editPreferencesAddNewSubmit(e, true) }}>
                                                {
                                                    editPreferencesAddNew ?
                                                        (<svg style={{ fill: editPreferencesAddNew ? 'black' : 'white' }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                                            <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
                                                        </svg>)
                                                        :
                                                        (<svg style={{ fill: editPreferencesAddNew ? 'black' : 'white' }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                                            <path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z" />
                                                        </svg>)
                                                }
                                            </button>
                                        </div>
                                        {editPreferencesAddNew && <div className="icon" >
                                            <button style={{ backgroundColor: 'rgb(255, 187, 187)' }} title='Cancel'
                                                onClick={editPreferencesAddNewClose}>
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                                                    <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
                                                </svg>
                                            </button>
                                        </div>}
                                    </div>
                                </div>

                                {editPreferences.map((row, i) =>
                                (<div className="field" key={i} style={{ outline: (row.is_active === 1) ? '2px solid rgb(1, 160, 1)' : (row.is_active === -1) ? '2px solid red' : 'none' }}>
                                    <input type="checkbox" name={row.user} id=""
                                        onChange={handleEditPreferencesChechUncheck} checked={(row?.is_active === 1) || false} />
                                    <label style={{ textDecoration: (row.is_active === -1) ? 'line-through' : 'none', color: (row.is_active === -1) ? 'red' : 'black' }}>{row.user}</label>
                                    <button onClick={() => { editPreferencesHandleDelete(row.id) }} type='button'>
                                        {
                                            (row.is_active === -1) ?
                                                (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                    <path d="M125.7 160H176c17.7 0 32 14.3 32 32s-14.3 32-32 32H48c-17.7 0-32-14.3-32-32V64c0-17.7 14.3-32 32-32s32 14.3 32 32v51.2L97.6 97.6c87.5-87.5 229.3-87.5 316.8 0s87.5 229.3 0 316.8s-229.3 87.5-316.8 0c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0c62.5 62.5 163.8 62.5 226.3 0s62.5-163.8 0-226.3s-163.8-62.5-226.3 0L125.7 160z" />
                                                </svg>)
                                                :
                                                (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                                    <path d="M135.2 17.7C140.6 6.8 151.7 0 163.8 0H284.2c12.1 0 23.2 6.8 28.6 17.7L320 32h96c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 96 0 81.7 0 64S14.3 32 32 32h96l7.2-14.3zM32 128H416V448c0 35.3-28.7 64-64 64H96c-35.3 0-64-28.7-64-64V128zm96 64c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16z" />
                                                </svg>)
                                        }
                                    </button>
                                    <div className="line"></div>
                                </div>)
                                )}
                            </form>
                            <div className="btns">
                                <button onClick={() => { setEditPreferences(null) }}>Cancel</button>
                                <button onClick={handleEditPreferencesSubmit}>Submit</button>
                            </div>
                        </div>
                    </div>
                )}
            </>}
            {popUp && (
                <div className="popUp">
                    <div className="popUpCard">
                        <p dangerouslySetInnerHTML={{ __html: popUp }}></p>
                        <div className="startNowBtn">
                            <button onClick={() => { setPopUp('') }}>Okay</button>
                        </div>
                    </div>
                </div>
            )}

            {userGridData && enableShowChat && <ChatPreview userData={userData} handleBack={() => { setEnableShowChat(false) }} />}

            {/* <div className="startNowBtn">
                <button onClick={() => {
                    setPopUp('<a>Error:</a> Apple has not accepted your iCloud ID or Password, try again after a few minutes or contact the Support Team.');
                    setTimeout(popUpClose, 5000);
                }}>test</button>
            </div> */}

        </div>
    );
};

export default Home;
