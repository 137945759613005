import { useState, useEffect, useCallback, useRef } from 'react';
// import { UserContext, headers } from "../App";
import {
    MainContainer, ChatContainer, ConversationHeader, MessageList, Message,
    ConversationList, Conversation, Avatar, Sidebar, Search, MessageSeparator
} from '@chatscope/chat-ui-kit-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import ClipLoader from "react-spinners/ClipLoader";
import { LinkPreview } from './LinkPreview';
import '../Styles/ChatPreview.css'
import axios from 'axios';

let searchInProgress = false;

export default function ChatPreview({ userData, handleBack }) {
    // const searchParams = useSearchParams();
    const [user, setUser] = useState(userData);
    // console.log(user);
    // useEffect(() => {
    //     const param1 = searchParams.get('username');
    //     const param2 = searchParams.get('name');
    //     const param3 = searchParams.get('imgurl');
    //     const param4 = searchParams.get('thumburl');
    //     setUser({
    //         username: param1,
    //         name: param2,
    //         profile_image_url: param3,
    //         profile_thumbnail_url: param4
    //     });
    // }, []);

    const [conversations, setConversations] = useState([]);
    const [session, setSession] = useState({});
    const [currentConversation, setCurrentConversation] = useState({});
    const [messageList, setMessageList] = useState([]);
    const [showMessageList, setShowMessageList] = useState(false);
    const [loading, setLoading] = useState(true);
    const [sidebarWidth, setSidebarWidth] = useState(25);
    const [isResizing, setIsResizing] = useState(false);
    const [query, setQuery] = useState('');
    const [searchResults, setSearchResults] = useState({});
    const defaultProfileUrl = 'https://ui-avatars.com/api/?background=random&name=';
    const sidebarRef = useRef(null);
    const md = 768;
    const laterThan = 15;
    var prevMsgTime, prevSender;
    const conversationPrefix = "Chat with";
    const LINK_PREVIEW_API_URL = 'https://ec2-3-138-174-82.us-east-2.compute.amazonaws.com:4000';


    const handleGoingBack = () => { handleBack(); }

    const startResizing = useCallback((mouseDownEvent) => {
        if (window.innerWidth > md) {
            setIsResizing(true)
        }
    }, [])

    const stopResizing = useCallback(() => {
        setIsResizing(false)
    }, [])

    const resize = useCallback((mouseMoveEvent) => {
        if (isResizing) {
            var width = Math.round((mouseMoveEvent.clientX) / window.innerWidth * 100);
            setSidebarWidth(width > 50 ? 50 : width);
        }
    }, [isResizing])
    const fetchConversations = () => {
        setMessageList([])
        axios.get('OUR_BACKEND/v0.1/users/' + user.username + '/chats')
            .then(resp => {
                let data = resp.data;
                if (data.length > 0) {
                    if (window.innerWidth > md) {
                        data[0].active = true;
                        fetchMessageList(data[0].room_id);
                        setCurrentConversation(data[0]);
                    } else {
                        setLoading(false);
                    }
                } else {
                    setLoading(false);
                }
                setConversations(data);

            });
    };

    const fetchSession = () => {
        if (session.updatedTime) {
            return;
        }
        axios.get('OUR_BACKEND/v0.1/users/' + user.username + '/imessage_session_log/non_null_msg_captured/latest')
            .then(resp => {
                let data = resp.data;
                if (data) {
                    var date = new Date(0);
                    date.setUTCMilliseconds(data.inserted_time);
                    var elapseTime = Math.ceil((Math.abs(date - new Date())) / (1000 * 60 * 60));
                    data.updatedTime = date
                    data.syncActive = elapseTime < 24;
                    setSession(data);
                }

            });
    };

    const fetchMessageList = (conversationId) => {
        setMessageList([]);
        setLoading(true);
        const date = new Date();
        date.setDate(date.getDate() - laterThan);
        axios.get('OUR_BACKEND/v0.1/users/' + user.username + '/' + conversationId + '/messages?laterThan=' + Math.round(date.getTime() / 1000))
            .then(resp => {
                let data = resp.data;
                if (data && data.messages) {
                    setMessageList(data.messages);
                }
                setLoading(false);
            });
    }
    const setConversationActive = (conversation, conversationList) => {
        if (!conversation || !conversation.room_id ||
            (currentConversation && currentConversation.room_id === conversation.room_id)) {
            if (!conversation) {
                setMessageList([]);
                setCurrentConversation(conversation);
            }
            return;
        }
        if (!conversationList) {
            conversationList = conversations;
        }
        const temp = conversationList.map((conv) => {
            if (conv.room_id === conversation.room_id) {
                conv.active = true;
                fetchMessageList(conv.room_id);
            } else {
                conv.active = false;
            }
            return conv;
        });
        setShowMessageList(true);
        setCurrentConversation(conversation);
        setConversations(temp);


    }
    useEffect(() => {
        if (conversations.length === 0) {
            fetchConversations();
        }
        if (!session.hasOwnProperty('updated_time')) {
            fetchSession();
        }
    }, [conversations, session]);
    useEffect(() => {
        if (window.innerWidth > md) {
            setShowMessageList(true);
            window.addEventListener("mousemove", resize)
            window.addEventListener("mouseup", stopResizing)
            return () => {
                window.removeEventListener("mousemove", resize)
                window.removeEventListener("mouseup", stopResizing)
            }

        } else {
            setSidebarWidth(100);
            setShowMessageList(false);
        }
    }, [resize, stopResizing]);


    const getMessageTime = (msg_time) => {
        if (!msg_time) {
            return "";
        }
        const date = new Date(0);
        date.setUTCSeconds(msg_time);
        const today = new Date();
        if ((date.toDateString() === today.toDateString())) {
            return date.toLocaleTimeString('en-US', { hour: "2-digit", minute: "2-digit" });
        } else {
            return date.toLocaleDateString('en-US');
        }
    };


    const getConversation = (conversation, conversationList) => {
        const last_msg_time = getMessageTime(conversation.sent_time);
        var subject = conversation && conversation.subject ? conversation.subject.replace(conversationPrefix, '').trim() : '';
        return currentConversation && currentConversation.room_id && conversation.room_id === currentConversation.room_id ?
            (<div className="cs-conversation cs-conversation--active">
                <Avatar src={conversation.profile_thumbnail_url ? conversation.profile_thumbnail_url : (defaultProfileUrl + subject)} name={subject} />
                <Conversation.Content>
                    <div className="cs-conversation__name" dangerouslySetInnerHTML={{ __html: highlightSearchText(query, conversation.subject) }}></div>
                    <div className="cs-conversation__info">
                        <div className="cs-conversation__last-sender" dangerouslySetInnerHTML={{ __html: (conversation.sender_name ? highlightSearchText(query, conversation.sender_name) + ":" : '') }}></div>
                        <div className="cs-conversation__info-content" dangerouslySetInnerHTML={{ __html: highlightSearchText(query, conversation.msg_text) }}></div>
                    </div>
                </Conversation.Content>
                <div className="cs-conversation__last-activity-time" title={last_msg_time}>{last_msg_time}</div>
            </div>
            ) :
            (<><div className="cs-conversation" onClick={() => setConversationActive(conversation, conversationList)}>
                <Avatar src={conversation.profile_thumbnail_url ? conversation.profile_thumbnail_url : (defaultProfileUrl + subject)} name={subject} />
                <Conversation.Content>
                    <div className="cs-conversation__name" dangerouslySetInnerHTML={{ __html: highlightSearchText(query, conversation.subject) }}></div>
                    <div className="cs-conversation__info">
                        <div className="cs-conversation__last-sender" dangerouslySetInnerHTML={{ __html: (conversation.sender_name ? highlightSearchText(query, conversation.sender_name) + ":" : '') }}></div>
                        <div className="cs-conversation__info-content" dangerouslySetInnerHTML={{ __html: highlightSearchText(query, conversation.msg_text) }}></div>
                    </div>
                </Conversation.Content>
                <div className="cs-conversation__last-activity-time" title={last_msg_time}>{last_msg_time}</div>
            </div>
                <div className="conversation-avatar-spacer">
                    <div className="conversation-separator"></div>
                </div>
            </>);
    }

    const getMessageSeparatorTime = (msgTime) => {
        const today = new Date();
        if ((msgTime.toDateString() === today.toDateString())) {
            return "Today";
        } else {
            return msgTime.toLocaleDateString('en-US', { weekday: "long", day: "numeric", year: "numeric", month: "long" });
        }
    }

    const getMessageSeparator = (msgTime, timeChanged) => {
        if (!timeChanged) {
            return
        }

        return (<MessageSeparator content={getMessageSeparatorTime(msgTime)} />)

    }

    const backToConversation = () => {
        setLoading(true);
        setShowMessageList(false);
        setCurrentConversation({});
        setMessageList([]);
        setTimeout(() => {
            setLoading(false);
        }, 1000)
    }

    const downloadFile = () => {
        if (!currentConversation || messageList.length === 0) {
            return;
        }
        // create file in browser
        const fileName = currentConversation.subject ? currentConversation.subject : 'messages';
        const json = JSON.stringify(messageList, null, 4);
        const blob = new Blob([json], { type: "application/json" });
        const href = URL.createObjectURL(blob);

        // create "a" HTLM element with href to file
        const link = document.createElement("a");
        link.href = href;
        link.download = fileName + ".json";
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
    }

    const getUrlForPreview = (msg_text) => {
        return msg_text ? msg_text.match(/\bhttps?:\/\/\S+/gi) : null;
    }

    const getLinkPreview = (url) => {
        return (
            <div>
                <LinkPreview
                    external={false}
                    href={url}
                    host={LINK_PREVIEW_API_URL}

                />
            </div>

        )
    }

    const getMessage = (msg) => {
        var msgTime = new Date(0);
        msgTime.setUTCSeconds(msg.sent_time);
        var dateChanged = !prevMsgTime || prevMsgTime.toDateString() !== msgTime.toDateString();
        var timeChanged = !prevMsgTime ||
            prevMsgTime.toLocaleTimeString('en-US', { hour: "2-digit", minute: "2-digit" }) !==
            msgTime.toLocaleTimeString('en-US', { hour: "2-digit", minute: "2-digit" });
        var senderChanged = !prevSender || (msg.sender_name && prevSender !== msg.sender_name);
        var urlsForPreview = getUrlForPreview(msg.msg_text);
        if (msg.sender_name) {
            prevSender = msg.sender_name;
        }
        prevMsgTime = msgTime;

        return (
            <>
                {getMessageSeparator(msgTime, dateChanged)}
                {msg.msg_type === 'chat' && (<Message model={{
                    sentTime: msgTime.toLocaleTimeString('en-US', { hour: "2-digit", minute: "2-digit" }),
                    sender: msg.sender_name === user.name ? 'You' : msg.sender_name,
                    direction: (msg.sender === user.contact_id) || (prevSender === user.name) ? 'outgoing' : 'incoming',
                    position: msg.sender_name ? 'first' : 'normal'
                }} avatarSpacer={senderChanged && msg.sender_name ? false : true} avatarPosition="tl">

                    {(senderChanged || timeChanged) && (<Message.Header sender={senderChanged ? msg.sender : ''}
                        sentTime={msgTime.toLocaleTimeString('en-US', { hour: "2-digit", minute: "2-digit" })} />)}
                    {senderChanged && msg.sender && (<Avatar src={msg.profile_thumbnail_url ? msg.profile_thumbnail_url : (defaultProfileUrl + msg.sender)} name={msg.sender} />)}
                    <Message.CustomContent>
                        <div className="cs-message__content">
                            <div className="cs-message__html-content" dangerouslySetInnerHTML={{ __html: highlightSearchText(query, msg.msg_text) }}></div>
                        </div>
                        {urlsForPreview && (<div className="cs-message__content">
                            {urlsForPreview.map(url => getLinkPreview(url))}
                        </div>)}
                    </Message.CustomContent>
                </Message>)}
            </>
        )

    }
    const performSearch = (q) => {
        setQuery(q);
        setSearchResults({});
        setConversationActive(null);
        if (q.length === 0) {
            searchInProgress = false;
            clearSearch();
            return;
        }
        searchInProgress = true;
    };
    useEffect(() => {
        if (searchInProgress) {
            const timeOutId = setTimeout(() => {
                setLoading(true);
                const date = new Date();
                date.setDate(date.getDate() - laterThan);
                axios.get('OUR_BACKEND/v0.1/users/' + user.username + '/data?searchFor=' + query + '&maxItemsForEach=15')
                    .then(resp => {
                        let data = resp.data;
                        setSearchResults(data);
                        setLoading(false);
                        searchInProgress = false;

                    });
            }, 500);
            return () => clearTimeout(timeOutId);
        }
    }, [query, user.username])

    const clearSearch = () => {
        setQuery('');
        setSearchResults({});
        setConversationActive(null);
        setConversations([]);
        fetchConversations();
    }

    const highlightSearchText = (oldSearch, msg_text) => {
        let search = oldSearch;
        search = search.replace(
            /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
            ''
        ).replaceAll(/\s+/g, '').replaceAll('+', '').trim();

        if (!search || search === "" || !msg_text) {
            return msg_text;
        }
        let re = '';
        try {
            re = new RegExp(search, "gi");
        } catch (err) {
            return '';
        }
        var array;
        var start = 0;
        var result = '';
        while ((array = re.exec(msg_text)) !== null) {
            result += msg_text.slice(start, array.index) + '<mark>' + array[0] + '</mark>';
            start = start + array.index + search.length;
        }
        result += msg_text.slice(start, msg_text.length);
        return result;

    }
    const displayChats = () => {
        return searchResults.chats && searchResults.chats.length > 0 &&
            (<><div as Conversation className="result-section">
                CHATS
            </div>
                {searchResults.chats.map(conversation => conversation && getConversation(conversation, searchResults.chats))}
            </>)
    }
    const displayContacts = () => {
        return searchResults.contacts && searchResults.contacts.length > 0 &&
            (
                <>
                    <div as Conversation className="result-section">
                        CONTACTS
                    </div>
                    {searchResults.contacts.map(contact => getConversation({
                        subject: contact.name,
                        msg_text: contact.msg_text,
                        sent_time: contact.sent_time,
                        room_id: contact.room_id
                    }, searchResults.contacts))}
                </>
            )
    }

    const displayMessages = () => {
        return searchResults.messages && searchResults.messages.length > 0 &&
            (
                <>
                    <div as Conversation className="result-section">
                        MESSAGES
                    </div>
                    {searchResults.messages.map(message => getConversation(message, searchResults.messages))}
                </>
            )
    }
    const displaySearchResults = () => {
        return (
            <>
                {displayChats()}
                {displayContacts()}
                {displayMessages()}

            </>
        )


    }

    // const testLocalApi = async () => {
    //   const res = await fetch('OUR_BACKEND/testApi', {
    //     method: 'GET',
    //     headers: {

    //     }
    //   })
    //   console.log(res);
    // }

    prevMsgTime = null;
    prevSender = null;
    var subject = currentConversation && currentConversation.subject ? currentConversation.subject.replace(conversationPrefix, '').trim() : '';
    //console.log("session.updatedTime :" + session.updatedTime);
    // console.log("sidebarWidth :" + sidebarWidth);
    // console.log("showMessageList :" + showMessageList);
    // console.log("window.innerWidth > md - " + ((window.innerWidth > md) || ((window.innerWidth < md) && showMessageList)));
    return (
        <div className="h-screen">
            {/* <button onClick={testLocalApi}>test local api</button> */}
            <div className={"loader " + (loading ? 'active' : '')}>
                <ClipLoader
                    color="#3a7fdb"
                    loading={loading}
                    cssOverride={{}}
                    size={90}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                />
            </div>
            <MainContainer >
                <div style={{
                    width: sidebarWidth + '%', height: '100vh',
                    display: ((window.innerWidth > md) || ((window.innerWidth < md) && !showMessageList)) ? 'block' : 'none'
                }}>
                    <Sidebar position="left" scrollable={false} className="sidebar">
                        <ConversationHeader>
                            <Avatar src={user.profile_thumbnail_url ? user.profile_thumbnail_url : (defaultProfileUrl + user.name)} name={user.name} />
                            <ConversationHeader.Content >
                                <div as ConversationHeaderContent className="cs-conversation-header__content">
                                    <div as ConversationHeaderContent className="cs-conversation-header__user-name">Current Status:
                                        {session.syncActive ? (<span> Active <span className="dot"></span></span>) : (<span> Inactive <span className="dot inactive"></span></span>)}
                                    </div>

                                    {session.updatedTime && (<div className="cs-conversation-header__info">Captured {session.messages_captured} messages as of {(session.updatedTime.toLocaleTimeString('en-US', { hour: "2-digit", minute: "2-digit" }) +
                                        ' on ' + session.updatedTime.toLocaleDateString('en-US'))}
                                    </div>)}
                                    <button className="backBtn" onClick={handleGoingBack}>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                            <path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" />
                                        </svg>
                                        Back
                                    </button>
                                </div>
                            </ConversationHeader.Content>
                        </ConversationHeader>
                        <Search placeholder="Search..." value={query} onChange={(v) => performSearch(v)} onClearClick={() => clearSearch()} />
                        <ConversationList>
                            {
                                query === '' ? conversations.length > 0 &&
                                    conversations.map(conversation => conversation && getConversation(conversation))
                                    : displaySearchResults()
                            }


                        </ConversationList>

                    </Sidebar>
                </div>
                <div id="resizer" ref={sidebarRef} onMouseDown={startResizing} style={{ display: window.innerWidth > md ? 'block' : 'none' }}></div>
                <div style={{
                    display: ((window.innerWidth > md) || ((window.innerWidth < md) && showMessageList)) ? 'block' : 'none',
                    width: (window.innerWidth > md) ? (100 - sidebarWidth) + '%' : '100%'
                }}>
                    <ChatContainer>
                        <ConversationHeader>
                            {window.innerWidth < md && (<ConversationHeader.Back onClick={backToConversation} />)}
                            <Avatar src={currentConversation && currentConversation.profile_thumbnail_url ? currentConversation.profile_thumbnail_url : (defaultProfileUrl + subject)} name={subject} />
                            <ConversationHeader.Content userName={currentConversation ? currentConversation.subject : ''} info={currentConversation ? "messages for last " + laterThan + " days" : ''} />
                            <ConversationHeader.Actions>
                                {window.innerWidth < md && (<FontAwesomeIcon icon={faDownload} className="cursor-pointer export" onClick={downloadFile} />)}
                            </ConversationHeader.Actions>
                        </ConversationHeader>
                        <MessageList>

                            {
                                messageList.length > 0 &&
                                messageList.map(msg => msg && getMessage(msg))
                            }

                        </MessageList>
                        <div as="MessageInput" style={{ display: ((window.innerWidth > md && showMessageList)) ? 'flex' : 'none' }}
                            className="cs-conversation-header">
                            <div className="cs-conversation-header__content">
                            </div>
                            <section className="cs-conversation-header__actions md-height">
                                <FontAwesomeIcon icon={faDownload} className="cursor-pointer export" onClick={downloadFile} />
                            </section>

                        </div>

                    </ChatContainer>
                </div>
            </MainContainer>
        </div>
    );

}